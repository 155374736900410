<template>
  <div class="chatContainer">
    <iframe class="chat" v-if="src" :src="src" frameborder="0"/>
    <flixLoader v-else />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      src: false
    }
  },
  methods: {
    getChatByID () {
      var user = this.$getUserVariables()
      if (!user || typeof user.user === 'undefined') {
        return false
      }

      this.$flix_post({
        url: 'support_chats/get_by_user_id',
        data: {
          user: user.user.md5_id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.src = 'https://support.bookingflix.com/' + this.$i18n.locale + '/' + ret.data[1] + '/' + btoa(0) + '.html'
          } else {
            this.src = 'https://support.bookingflix.com/' + this.$i18n.locale + '/chat.html'
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getChatByID()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .chatContainer
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    .chat
      width: 100%
      height: 100vh
      max-width: 800px
      margin: 0 auto
</style>
